import React from 'react'
import box from '../../assets/Telegram/box.png'
import tele from '../../assets/Telegram/tele.png'
import styles from '../../styles'
import right1 from '../../assets/right.png'
import CatButton from '../Home/CatButton'
const Sec4_Telegram = () => {
  return (
    <div className="flex flex-col items-center   text-center  p-5 md:p-10">
      <div className="flex space-x-4 mb-4">
        <img src={tele} alt="Telegram Icon" className="h-12 w-12" />

        <span className="text-black text-5xl font-semibold">+</span>

        <img src={box} alt="Grid Icon" className="h-12 w-12" />
      </div>

      <h1 className="font-600 flex items-center mb-4  text-[32px] xs:text-[28px] text-black  font-clash-grotesk font-semibold">
        Elevate your Telegram Community
      </h1>

      <h2 className="text-gray-600 mb-6  font-clash-grotesk text-[24px] font-semibold ">
        Create. Compete. Engage.
      </h2>

      <p className="text-black text-lg font-normal mt-4 max-w-md break-words font-clash-grotesk ">
        Create, manage, and wager on challenges effortlessly with Catoff—no
        downloads needed. Instantly boost engagement and fun within your chat.
      </p>

      <div className={`${styles.marginX} mb-[80px] `}>
        <CatButton
          text="Empower Today"
          bgColor="#DEFF5A"
          iconRight={right1}
          onClick={() => console.log('Join clicked')}
          link="https://game.catoff.xyz/"
          isExternal={true}
        />
      </div>
    </div>
  )
}

export default Sec4_Telegram
