import React, { useState } from 'react'
import { Content, Footer, Header, Sidebar } from '../components'

function CatPaper() {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-shrink-0 mt-10">
        <Header />
      </div>

      <div className="flex flex-1">
        <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />

        <Content isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>

      <div className="flex-shrink-0">
        <Footer />
      </div>
    </div>
  )
}

export default CatPaper
