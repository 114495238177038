import React from 'react'
import leftWave from '../../assets/LeftWave.svg'
import rightWave from '../../assets/RightWave.svg'
import bigSparkle from '../../assets/BigSparkel.svg'
import CarCup from '../../assets/CarCup.svg'
import trophyImg from '../../assets/Trophee.png'
import right1 from '../../assets/right.png'
import sparkel from '../../assets/sparkel.svg'
import buzzer from '../../assets/Buzzer.png'

import styles from '../../styles'
import ClickableButton from '../ClickButton'
import CatButton from './CatButton'

const Hero = () => {
  return (
    <div className={`flex flex-col items-center py-0 md:py-2 px-4`}>
      <div className="flex items-center justify-center p-4 w-full relative gap-10 my-10">
        <img
          src={sparkel}
          alt="Sparkle"
          className="w-12 h-auto relative top-10 hidden md:block "
        />
        <span className="">
          <ClickableButton
            text="Announcing Catoff on Solana Mainnet"
            bgColor="#DEFF5A"
            iconRight={right1}
            fontSize="text-[11px] md:text-[18px]"
            iconLeft={buzzer}
            onClick={() => console.log('Join clicked')}
            link="https://medium.com/@catoffgaming/catoff-is-live-on-solana-mainnet-0dfc16bb3911"
            isExternal={true}
          />
        </span>
        <img
          src={sparkel}
          alt="Sparkle"
          className="w-12 h-auto relative -top-10 hidden md:block"
        />
      </div>
      <div class="absolute hidden md:block -z-[1] w-[60px] h-[900px] left-[129px] -top-[80px] bg-white opacity-45 border border-[rgba(0,0,0,0.1)] transform rotate-[30deg]"></div>

      {/* Slogan Section */}
      <div className="flex flex-col md:flex-row justify-around items-center md:gap-12 w-full">
        <div className="flex items-center gap-4">
          <img
            src={leftWave}
            alt="Left Wave"
            className="max-w-xs hidden sm:block"
          />
          <img
            src={CarCup}
            alt="Car Cup"
            className="w-24 relative bottom-12 hidden sm:block"
          />
        </div>
        <div className="flex flex-wrap justify-center md:justify-start items-center font-bold text-[40px] sm:text-[54px] md:text-[66px] font-clash-grotesk px-2 text-center md:text-left">
          <span className="mr-2 text-black text-[30px] sm:text-[54px] md:text-[66px]">
            Challenge.
          </span>
          <span className="mr-2 text-black text-[30px] sm:text-[54px] md:text-[66px]">
            Wager.
          </span>
          <span className="relative text-[#b0e000] mr-2 text-[30px] sm:text-[54px] md:text-[66px]">
            Win.
            <span className="absolute left-0 w-full h-1 bg-[#b0e000] bottom-[-6px]"></span>
            <span className="absolute left-0 w-full h-1 bg-black bottom-[-10px]"></span>
          </span>
          <img
            src={trophyImg}
            alt="Trophy"
            className="w-[60px] h-[40px] sm:w-[93px] sm:h-[60px] ml-2 mt-4 md:mt-0"
          />
        </div>

        <div className="flex items-center gap-4">
          <img
            src={bigSparkle}
            alt="Big Sparkle"
            className="max-w-xs hidden sm:block"
          />
          <img
            src={rightWave}
            alt="Right Wave"
            className="max-w-xs relative bottom-12 hidden sm:block"
          />
        </div>
      </div>

      <p className="text-center text-gray-600 font-clash-grotesk text-[20px] sm:text-[28px] font-medium leading-[24px] sm:leading-[34.44px] mt-5 w-full sm:w-2/5 px-2">
        Turn everyday activities into epic challenges! Challenge friends to
        dares, engage in 1-on-1 duels, or join global pools for endless fun.
      </p>
      <div className={`${styles.marginX} mt-[10px] mb-[80px]`}>
        <CatButton
          text="Join a Challenge"
          bgColor="#DEFF5A"
          iconRight={right1}
          onClick={() => console.log('Join clicked')}
          link="https://game.catoff.xyz/"
          isExternal={true}
        />
      </div>
    </div>
  )
}

export default Hero
