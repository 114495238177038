// src/CatEye.js
import React, { useState, useEffect } from 'react';
import openEye from '../../assets/open.svg'
import closedEye from '../../assets/closed.png';
import halfBlinkEye from '../../assets/open.svg';

const CatEye = () => {
  const [currentImage, setCurrentImage] = useState(openEye);

  useEffect(() => {
    const blinkSequence = [
      openEye,
      halfBlinkEye,
      closedEye,
      halfBlinkEye,
      openEye
    ];

    let index = 0;
    const interval = setInterval(() => {
      setCurrentImage(blinkSequence[index]);
      index = (index + 1) % blinkSequence.length;
    }, 200); 

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <img src={currentImage} alt="Cat Eye" style={{ width: '100px', height: '60px' }} />
    </div>
  );
};

export default CatEye;
