import React from 'react'
import styled from 'styled-components'
import doc1 from '../../assets/doc1.svg'
// import Footer from './Footer'
import { useSwipeable } from 'react-swipeable'
const ContentWrapper = styled.div`
  margin-left: 320px; /* Adjust margin for desktop view */
  padding: 20px;
  overflow-x: hidden;

  @media (max-width: 768px) {
    margin-left: 0;
    padding: 15px; /* Adjust padding for mobile view */
  }
`
const RowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 40px; /* Increased space below the row */
  margin-top: 10px; /* Increased space below the row */

  @media (max-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`

const Title = styled.h1`
  color: black;
  font-size: 36px;
  font-family: 'Clash Grotesk', sans-serif;
  font-weight: 600;
  word-wrap: break-word;
  margin-right: 20px; /* Space between title and image */

  @media (max-width: 768px) {
    font-size: 22px; /* Responsive font size */
    margin-bottom: 10px; /* Add space when stacked vertically */
  }
`
const Image = styled.img`
  width: 60px; /* Example width, adjust as needed */
  height: auto;

  @media (max-width: 768px) {
    width: 50px;
  }
`
const Section = styled.section`
  border-bottom: 1px solid #e0e0e0; /* Add a bottom border for separation */
  padding: 20px 0;
  transition: background-color 0.3s ease-in-out;
  width: 90%;
  h2 {
    font-size: 24px;
    color: #221f20;
    font-family: 'Clash Grotesk', sans-serif;
    font-weight: 500;
    margin-bottom: 10px;
  }

  p {
    color: black;
    font-size: 20px;
    font-family: 'SF Pro', sans-serif;
    font-weight: 400;
    letter-spacing: 0.2px;
    word-wrap: break-word;
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 22px;
    }

    p {
      font-size: 16px;
      margin-bottom: 15px;
    }
  }
`

const Subtitle = styled.p`
  font-size: 20px;
  font-family: 'Clash Grotesk', sans-serif;
  color: #221f20;
  font-weight: 500;
  margin-bottom: 50px; /* Add space below the subtitle */

  @media (max-width: 768px) {
    font-size: 18px;
    margin-bottom: 30px; /* Adjust space for mobile */
  }
`
const BulletPoints = styled.ul`
  color: black;
  font-size: 16px;
  font-family: 'SF Pro', sans-serif;
  font-weight: 400;
  letter-spacing: 0.2px;
  word-wrap: break-word;
  list-style-type: disc; /* Default bullet style */
  margin-left: 50px; /* Indent for bullets */
  padding-left: 0;

  li {
    margin-bottom: 2px; /* Spacing between bullet items */
    line-height: 1.6; /* Adjusted line height for readability */
  }
`
const Content = ({ isOpen, setIsOpen }) => {
  return (
    <>
      <ContentWrapper>
        <RowContainer>
          <button
            onClick={() => setIsOpen(true)}
            className="block md:hidden focus:outline-none"
          >
            <svg
              className="w-6 h-6 text-black"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {isOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              )}
            </svg>
          </button>
          <Title>Catpaper (Catoff Whitepaper)</Title>

          <Image src={doc1} alt="Catpaper Image" />
        </RowContainer>

        <Subtitle>Empowering Real-World Challenges on Solana</Subtitle>

        <Section id="executive-summary">
          <h2>Executive Summary</h2>
          <p>
            Catoff is a peer-to-peer (P2P) challenge wagering platform built on
            Solana. Our mission is to inspire individuals to be more active,
            adventurous, and socially engaged. We fundamentally aim to encourage
            people to try new things, learn new skills, and travel more. Our
            goal is to provide a seamless, decentralized environment where users
            can participate in a variety of fitness and skill-based challenges,
            dares, and duels.
          </p>
          <p>
            By leveraging the power of Solana, we ensure transparency, security,
            and rapid settlement of transactions.
          </p>
        </Section>
        <Section id="introduction">
          <h2>Introduction</h2>
          <p>
            Catoff was conceived as a solution to the growing disconnect between
            digital engagement and real-world experiences. By merging the
            excitement of social gaming with tangible, real-life activities,
            Catoff creates a unique platform that bridges the gap between
            virtual and physical worlds.
          </p>
          <p>
            The problem with current fitness and social gaming platforms:
            Existing platforms often lack real-world incentives, struggle with
            data transparency, and fail to create lasting motivation for users.
            Many are centralized, limiting global accessibility and introducing
            trust issues in fund management.
          </p>
          <p>
            The need for real-world incentives and transparent data tracking:
          </p>
          <BulletPoints>
            <li>Transparent and immutable record-keeping</li>
            <li>Instant, secure financial transactions</li>
            <li>A global, decentralized platform accessible to all</li>
            <li>Real-world incentives tied to digital achievements</li>
          </BulletPoints>
        </Section>

        <Section id="market-opportunity">
          <h2>Market Opportunity</h2>
          <p>
            The intersection of fitness apps, social gaming, and blockchain
            presents a significant opportunity. With the global fitness app
            market expected to reach $10.6 Billion by 2029 (CAGR of 18%) and the
            blockchain gaming market projected to hit $165.7 Billion by 2030
            (CAGR of 51.7%), Catoff is positioned at the forefront of a rapidly
            growing industry.
          </p>
          <p>
            Potential for growth in web3 and blockchain-based wagering: The
            decentralized finance (DeFi) sector, which includes blockchain-based
            wagering, is experiencing exponential growth. This trend, combined
            with increasing interest in health and fitness technologies, creates
            a fertile ground for Catoff's innovative approach.
          </p>
          <p>Target audience and demographics:</p>
          <BulletPoints>
            <li>
              Tech-savvy millennials and Gen Z individuals interested in fitness
              and personal challenges
            </li>
            <li>
              Blockchain enthusiasts looking for real-world applications of the
              technology
            </li>
            <li>
              Social gamers seeking more tangible Winnings and experiences
            </li>
            <li>
              Fitness enthusiasts interested in monetizing their activities and
              skills
            </li>
          </BulletPoints>
        </Section>

        <Section id="product-overview">
          <h2>Product Overview</h2>
          <p>
            Catoff is a decentralized platform that allows users to participate
            in, create, and wager on real-world challenges. These challenges can
            range from fitness goals and skill development to travel experiences
            and personal growth objectives.
          </p>
          <p>Key features and functionalities:</p>
          <BulletPoints>
            <li>
              P2P wagering on real-life activities: Users can create or join
              challenges and place wagers using Catoff's native token, CAT5.
            </li>
            <li>
              External validators and ZKproofs: To ensure fairness and accuracy,
              Catoff employs a network of external validators and zero-knowledge
              proofs to verify challenge completion.
            </li>
            <li>
              Secure smart contracts and automated payouts: All wagers and
              Winnings are managed through smart contracts on the Solana
              blockchain, ensuring transparency and immediate payouts.
            </li>
            <li>
              Social elements: Users can follow friends, share achievements, and
              engage in community discussions.
            </li>
            <li>
              Challenge creation tools: Easy-to-use interfaces for creating
              custom challenges and setting parameters.
            </li>
          </BulletPoints>
        </Section>

        <Section id="technology-stack">
          <h2>Technology Stack</h2>
          <p>
            Catoff is built on the Solana blockchain, chosen for its high speed,
            low transaction costs, and scalability. Solana's proof-of-history
            consensus mechanism allows for processing thousands of transactions
            per second, making it ideal for real-time wagering and payouts.
          </p>
          <p>Technical architecture of Catoff:</p>
          <BulletPoints>
            <li>
              Smart Contracts: Written in Rust, managing challenge creation,
              wagering, and reward distribution.
            </li>
            <li>
              Front-end: React-based web application and mobile apps for iOS.
            </li>
            <li>
              Back-end: Node.js servers for handling off-chain data and
              interfacing with the Solana blockchain.
            </li>
            <li>
              Data Storage: A combination of on-chain storage for critical data
              and off-chain solutions for user profiles and non-essential
              information.
            </li>
          </BulletPoints>
          <p>Security measures and protocols:</p>
          <BulletPoints>
            <li>Multi-signature wallets for treasury management</li>
            <li>
              Regular smart contract audits by reputable third-party firms
            </li>
            <li>Encryption of sensitive user data</li>
          </BulletPoints>
          <p>Integration with web2 technologies:</p>
          <BulletPoints>
            <li>
              APIs for integration with popular fitness tracking apps and
              devices
            </li>
            <li>
              Social media integration for sharing achievements and inviting
              friends
            </li>
            <li>Traditional payment gateways for fiat on-ramps</li>
          </BulletPoints>
        </Section>

        <Section id="user-experience">
          <h2>User Experience</h2>
          <p>How users interact with Catoff:</p>
          <BulletPoints>
            <li>Sign up and create a profile</li>
            <li>Browse available challenges or create their own</li>
            <li>Join challenges and place wagers using CAT5 tokens</li>
            <li>Complete challenges and submit proof of completion</li>
            <li>Receive Winnings automatically upon challenge verification</li>
          </BulletPoints>
          <p>Onboarding process for web2 users:</p>
          <BulletPoints>
            <li>Simple sign-up process using email</li>
            <li>Step-by-step tutorial on how to acquire and use CAT5 tokens</li>
            <li>
              Gradual introduction to blockchain concepts through in-app
              education
            </li>
          </BulletPoints>

          <p>Example use cases and scenarios:</p>
          <BulletPoints>
            <li>
              Fitness Challenge: Users join a 30-day workout challenge, wagering
              CAT5 tokens on their ability to complete daily exercises.
            </li>
            <li>
              Skill Development: A user creates a challenge to learn a new
              language, with milestones and wagers set for specific proficiency
              levels.
            </li>
            <li>
              Travel Adventure: Users participate in a challenge to visit 5 new
              countries within a year, with proof of travel verified by the
              platform.
            </li>
          </BulletPoints>
        </Section>
        <Section id="tokenomics">
          <h2>Tokenomics</h2>
          <p>Catoff utilizes two tokens:</p>
          <BulletPoints>
            <li>
              CAT5: The primary utility token used for wagers, winnings, and
              platform fees.
            </li>
            <li>
              veCAT5: A governance token earned by locking up CAT5, granting
              voting rights and additional benefits.
            </li>
          </BulletPoints>

          <p>How tokens are used within the platform:</p>
          <BulletPoints>
            <li>Wagers and challenge entry fees</li>
            <li>Winnings for challenge completion and validation</li>
            <li>Platform fees</li>
            <li>Governance voting (veCAT5)</li>
          </BulletPoints>

          <p>Incentives for users and validators:</p>
          <BulletPoints>
            <li>Staking rewards for locking up CAT5</li>
            <li>Boosted yields on locked-in wagers for veCAT5 holders</li>
            <li>
              Validator rewards for accurately verifying challenge completions
            </li>
          </BulletPoints>

          <p>Token Distribution:</p>
          <BulletPoints>
            <li>Total Supply: 1,000,000,000 CAT5</li>
            <li>30% - Community winnings and airdrops</li>
            <li>25% - Team and advisors (vested over 4 years)</li>
            <li>20% - Treasury and ecosystem development</li>
            <li>15% - Private sale</li>
            <li>10% - Public sale</li>
          </BulletPoints>
        </Section>
        <Section id="business-model">
          <h2>Business Model</h2>
          <p>Revenue streams and monetization strategy:</p>
          <BulletPoints>
            <li>Platform fees on wagers and challenge creation</li>
            <li>Premium features for power users</li>
            <li>Partnerships with brands for sponsored challenges</li>
            <li>Token appreciation through increased platform usage</li>
          </BulletPoints>

          <p>Partnerships and collaborations:</p>
          <BulletPoints>
            <li>Fitness tracking device manufacturers</li>
            <li>Travel companies for verification and Winnings</li>
            <li>Educational platforms for skill-based challenges</li>
            <li>Influencers and content creators for challenge promotion</li>
          </BulletPoints>

          <p>Long-term sustainability plan:</p>
          <BulletPoints>
            <li>Continuous development of new challenge types and features</li>
            <li>Expansion into new markets and demographics</li>
            <li>
              Building a robust decentralized autonomous organization (DAO) for
              community governance
            </li>
            <li>
              Reinvestment of platform fees into ecosystem growth and user
              acquisition
            </li>
          </BulletPoints>
        </Section>
        {/* <InfographicText>Infographic</InfographicText> */}
        <Section id="roadmap">
          <h2>Roadmap</h2>

          <p>Short-term milestones (Next 12 months):</p>
          <BulletPoints>
            <li>Q3 2024: Launch of beta platform with basic challenge types</li>
            <li>Q4 2024: Integration with major fitness tracking devices</li>
            <li>Q1 2025: Mobile app release for iOS</li>
            <li>
              Q2 2025: Implementation of advanced ZKproof validation system
            </li>
          </BulletPoints>

          <p>Long-term milestones (2-5 years):</p>
          <BulletPoints>
            <li>Expansion into AR/VR challenges</li>
            <li>Launch of Catoff DAO for full community governance</li>
            <li>Integration with major metaverse platforms</li>
            <li>
              Development of real-world rewards ecosystem with brand partners
            </li>
          </BulletPoints>

          <p>Key achievements to date:</p>
          <BulletPoints>
            {/* <li>Successful launch on Android</li>
            <li>PWA for smoother UX</li> */}
            <li>
              Reached 2k+ members on Telegram and 1k+ on X, growing organically
            </li>
            <li>
              Launched cross-platform Progressive Web App, increasing user
              accessibility
            </li>
            <li>Completed comprehensive smart contract security audit</li>
          </BulletPoints>
        </Section>
        {/* <InfographicText>Infographic</InfographicText>
        <h1>Team</h1>
        <InfographicText>content pending</InfographicText> */}
        <Section id="community-and-support">
          <h2>Community and Support</h2>

          <p>Building a community around Catoff:</p>
          <BulletPoints>
            <li>Active social media presence on Twitter and Telegram</li>
            <li>Regular AMAs with team members and advisors</li>
            <li>Community-driven challenge creation contests</li>
            <li>Local meetups and virtual events for Catoff users</li>
          </BulletPoints>

          <p>Channels for support and engagement:</p>
          <BulletPoints>
            <li>24/7 customer support via chat and email</li>
            <li>Comprehensive FAQ and knowledge base</li>
            <li>Community forums for peer-to-peer assistance</li>
            <li>Regular updates and newsletters</li>
          </BulletPoints>

          <p>Winnings and incentive programs for early adopters:</p>
          <BulletPoints>
            <li>Exclusive NFT badges for platform milestones</li>
            <li>Boosted rewards for early challenge creators</li>
            <li>Referral program with token incentives</li>
            <li>Early access to new features for active community members</li>
          </BulletPoints>
        </Section>
        <Section id="legal-and-compliance">
          <h2>Legal and Compliance</h2>

          <p>Regulatory considerations:</p>
          <BulletPoints>
            <li>
              Compliance with relevant gambling and gaming laws in operating
              jurisdictions
            </li>
            <li>KYC/AML procedures for user onboarding</li>
            <li>
              Regular legal reviews of platform operations and token utility
            </li>
          </BulletPoints>

          <p>Data privacy and user protection:</p>
          <BulletPoints>
            <li>GDPR compliance for data collection and storage</li>
            <li>Encryption of sensitive user information</li>
            <li>Option for users to delete their data upon account closure</li>
          </BulletPoints>

          <p>Terms of service and user agreements:</p>
          <BulletPoints>
            <li>
              Clear and concise terms of service outlining user rights and
              responsibilities
            </li>
            <li>
              Regular updates to reflect changes in platform functionality and
              legal requirements
            </li>
            <li>Transparent dispute resolution process</li>
          </BulletPoints>
        </Section>
        <Section id="conclusions">
          <h2>Conclusions</h2>

          <p>
            Catoff is poised to revolutionize the way people engage with
            personal challenges and social wagering. By leveraging blockchain
            technology, we create a transparent, secure, and motivating
            environment for users to push their boundaries and achieve their
            goals. Our vision is to build a global community of challenge
            enthusiasts, connected through the power of decentralized
            technology.
          </p>

          <p>
            We invite potential users, investors, and partners to join us on
            this exciting journey. Together, we can create a more active,
            adventurous, and interconnected world.
          </p>
        </Section>
        <Section id="appendix">
          <h2>Appendix</h2>

          <p>Glossary of terms:</p>
          <BulletPoints>
            <li>CAT5: Primary utility token of the Catoff platform</li>
            <li>veCAT5: Governance token earned by locking CAT5</li>
            <li>
              ZKproof: Zero-knowledge proof, a method of verification without
              revealing underlying data
            </li>
            <li>DAO: Decentralized Autonomous Organization</li>
          </BulletPoints>

          <p>Additional resources and references:</p>
          <BulletPoints>
            <li>
              Catoff Official Website:{' '}
              <a href="http://www.catoff.xyz" target="_blank">
                www.catoff.xyz
              </a>
            </li>
            <li>
              Catoff GitHub Repository:{' '}
              <a href="http://www.github.com/CatoffGaming" target="_blank">
                www.github.com/CatoffGaming
              </a>
            </li>
          </BulletPoints>

          <p>Contact information:</p>
          <BulletPoints>
            <li>
              Twitter:{' '}
              <a href="http://www.x.com/CatoffGaming" target="_blank">
                www.x.com/CatoffGaming
              </a>
            </li>
            <li>
              Telegram:{' '}
              <a href="http://t.me/catoffgaming" target="_blank">
                t.me/catoffgaming
              </a>
            </li>
            <li>
              LinkedIn:{' '}
              <a href="http://www.linkedin.com/company/catoff" target="_blank">
                www.linkedin.com/company/catoff
              </a>
            </li>
          </BulletPoints>
        </Section>

        {/* Add more sections as necessary */}
      </ContentWrapper>
      {/* <Footer /> */}
    </>
  )
}

export default Content
