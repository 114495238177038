// BanditPage.js
import React from 'react'
import { BanditContextProvider, Campaign } from '@bandit-network/react'

const Sec1_Bandit = React.memo(() => {
  return (
    <div className="flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gradient-to-b ">
      {/* Header Section */}
      <h1 className="text-4xl sm:text-5xl font-extrabold text-gray-900 mb-6 text-center">
        Quest with Catoff
      </h1>

      {/* Short description */}
      <p className="text-lg sm:text-xl text-gray-700 mb-10 text-center max-w-2xl leading-relaxed">
        Dive into our platform, join the community & discover the madness of P2P IRL Challenge Wagering on Solana.
      </p>

      {/* Campaign Section */}
      <div className="w-full max-w-2xl p-8 bg-white rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-2xl">
        <BanditContextProvider
          apiKey="e455f01e51834cdb8cda0408078295c1"
          cluster="mainnet"
          cssOverrides="GENERATED_PALETTE"
          appearance="light"
        >
          <Campaign campaignId="2054" displayMode="integrated" />
        </BanditContextProvider>
      </div>
    </div>
  )
})

export default Sec1_Bandit
