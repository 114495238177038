
import accountsReg from '../../assets/account_regs.svg'
import firstChallenge from '../../assets/first_challenge.svg'
import engageSocials from '../../assets/engage_socials.svg'

const cardData = {
  1: {
    expanded: [
      {
        title: 'Account Registration',
        description: 'Complete the entire registration process on Catoff',
        reward: 'Win 100 credits',
        imagePath:accountsReg
      },
      {
        title: 'First Challenge',
        description: 'Participate in your first challenge',
        reward: 'Win 200 credits',
        imagePath: firstChallenge,
      },
      {
        title: 'Engage on Social',
        description: 'Engage with our X and Instagram handles',
        reward: 'Win 50 credits',
        imagePath: engageSocials,
      },
    ],
    collapsed: [
      {
        title: 'Summary',
        description: 'Here is a brief summary of the card content.',
      },
    ],
  },
  2: {
    expanded: [
      {
        title: 'Compete',
        description: 'Compete in three challenges',
        reward: 'Win 300 credits',
        imagePath: accountsReg
      },
      {
        title: 'Place Side Wagers',
        description: 'Place two side wagers',
        reward: 'Win 200 credits',
        imagePath: firstChallenge
      },
      {
        title: 'Community',
        description: 'Participate in community discussions',
        reward: 'Win 200 credits',
        imagePath: engageSocials,
      },
    ],
    collapsed: [
      {
        title: 'Summary',
        description: 'Here is a brief summary of the card content.',
      },
    ],
  },
  3: {
    expanded: [
      {
        title: 'Host',
        description: 'Host one challenge on Catoff',
        reward: 'Win 400 credits',
        imagePath:accountsReg
      },
      {
        title: 'Place Side Wagers',
        description: 'Place three additional side wagers',
        reward: 'Win 300 credits',
        imagePath:firstChallenge
      },
      {
        title: 'Invite Friends',
        description: 'Invite three friends to Catoff',
        reward: 'Win 150 credits',
        imagePath: engageSocials,
      },
    ],
    collapsed: [
      {
        title: 'Summary',
        description: 'Here is a brief summary of the card content.',
      },
    ],
  },
  4: {
    expanded: [
      {
        title: 'Compete',
        description: 'Compete in five more challenges',
        reward: 'Win 500 credits',
        imagePath:accountsReg
      },
      {
        title: 'More Side Wagers',
        description: 'Place five additional side wagers',
        reward: 'Win 400 credits',
        imagePath:firstChallenge
      },
      {
        title: 'Attend Webinar',
        description: 'Attend a webinar or event hosted by Catoff team',
        reward: 'Win 200 credits',
        imagePath: engageSocials,
      },
    ],
    collapsed: [
      {
        title: 'Summary',
        description: 'Here is a brief summary of the card content.',
      },
    ],
  },
  5: {
    expanded: [
      {
        title: 'Compete',
        description: 'Reach ten total challenge participations',
        reward: 'Win 600 credits',
        imagePath:accountsReg
      },
      {
        title: 'Place More Side Wagers',
        description: 'Place eight total side wagers',
        reward: 'Win 500 credits',
        imagePath:firstChallenge
      },
      {
        title: 'Talk on Social Media',
        description: 'Share the experience on social media',
        reward: 'Win 200 credits',
        imagePath: engageSocials,
      },
    ],
    collapsed: [
      {
        title: 'Summary',
        description: 'Here is a brief summary of the card content.',
      },
    ],
  },
  6: {
    expanded: [
      {
        title: 'Host',
        description: 'Host two additional challlenges',
        reward: 'Win 700 credits',
        imagePath:accountsReg
      },
      {
        title: 'Take Charge',
        description: 'Take up on a community role',
        reward: 'Win 800 credits',
        imagePath:firstChallenge
      },
      {
        title: 'Invite More Friends',
        description: 'Invite five more friends',
        reward: 'Win 300 credits',
        imagePath: engageSocials,
      },
    ],
    collapsed: [
      {
        title: 'Summary',
        description: 'Here is a brief summary of the card content.',
      },
    ],
  },
  7: {
    expanded: [
      {
        title: 'The Catoff Conqueror',
        description: 'Complete all 12 main quests and 6 bonus quests Reward: 2000 tokens + exclusive NFT badge',
        reward: 'Win 2000 points + exclusive NFT badge',
        imagePath:accountsReg
      },
    ],
    collapsed: [
      {
        title: 'Summary',
        description: 'Here is a brief summary of the card content.',
      },
    ],
  },
}

const getContentById = (id) => {
  return cardData[id] || { expanded: [], collapsed: [] }
}

export default getContentById
