import React from 'react'
// import { FaTelegramPlane } from 'react-icons/fa'
import styles from '../../styles'

function Sec1_Telegram() {
  return (
    <div className="relative flex flex-col items-center justify-center  md:mt-[15px]  p-5 md:p-10 ">
      <div className="inline-block relative overflow-visible">
        <a
          href="https://t.me/catoff"
          className={`flex items-center  p-2 rounded ${styles.heading}  `}
          target="_blank"
          rel="noopener noreferrer"
        >
          {/* <FaTelegramPlane className="mr-2 text-5xl" /> Catoff on Telegram */}
        </a>
      </div>

      <div className="responsive-video">
        <div>
          <iframe
            className="video-iframe"
            src="https://www.youtube.com/embed/ITydZ6wBTD0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>

        <style jsx>{`
          .responsive-video {
            position: relative;
            width: 50%;
            padding-top: 30%; /* 16:9 Aspect Ratio */
          }
          .video-iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 12px;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
            border: 1px solid #ccc;
          }

          @media (max-width: 768px) {
            .responsive-video {
              position: relative;
              width: 100%;
              padding-top: 56.25%; /* 16:9 Aspect Ratio */
            }
            .video-iframe {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border-radius: 12px;
              box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
              border: 1px solid #ccc;
            }
          }
        `}</style>
      </div>
    </div>
  )
}

export default Sec1_Telegram
