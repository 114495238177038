import React from 'react'
import ClickableButton from '../ClickButton'
import right1 from '../../assets/right.png'
import styles from '../../styles'

const Cards = ({ imageSrc, text, buttonLabel, onClick, disabled }) => {
  return (
    <div className="flex flex-col items-center justify-center bg-white border border-black shadow-[6px_6px_0px_rgba(0,0,0,0.2)] p-6 ">
      {imageSrc && (
        <img
          src={imageSrc}
          alt={text}
          className="mb-4"
          style={{
            width: '500px',
            height: 'auto',
            objectFit: 'contain',
          }}
        />
      )}
      <h2
        style={{
          color: 'black',
          fontSize: '28px',
          fontFamily: 'Clash Grotesk',
          fontWeight: '600',
          lineHeight: '36px',
          letterSpacing: '0.28px',
          wordWrap: 'break-word',
        }}
        className={`text-center ${styles.heading}`}
      >
        {text}
      </h2>
      <span className="mt-4">
        <ClickableButton
          text={buttonLabel}
          bgColor="#DEFF5A"
          iconRight={right1}
          fontSize="text-[11px] md:text-[18px]"
          onClick={disabled ? null : onClick} 
          isExternal={true}
          disabled={disabled} 
        />
      </span>
    </div>
  )
}

export default Cards
