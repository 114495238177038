import React from 'react'
// import { FaTelegramPlane } from 'react-icons/fa'

import s4 from '../../assets/Telegram/s4.png'
import box from '../../assets/Telegram/box.png'
import s5 from '../../assets/Telegram/s5.png'
import s3 from '../../assets/Telegram/s3.png'
import sec3 from '../../assets/Telegram/sec3.png'
import styles from '../../styles'
import CatButton from '../Home/CatButton'
import right1 from '../../assets/right.png'
const Sec3_Telegram = () => {
  return (
    <div className="flex flex-col md:flex-row justify-between gap-10   p-5 md:p-10 ">
      <div className="flex flex-col justify-center align-center w-full md:w-[50%] md:pl-10">
        <h2
          className={` font-600 flex items-center mb-4  text-[32px] xs:text-[28px] text-black  font-clash-grotesk font-semibold`}
        >
          <img src={box} alt="not" className="w-12 h-10 mr-4" /> Catoff Mini App
        </h2>
        <p
          className={`text-gray-600 mb-6  font-clash-grotesk text-[24px] font-200 `}
        >
          Engage without ever leaving Telegram
        </p>

        {/* Features */}
        <ul className="space-y-4">
          <li className="flex items-start">
            <img src={s4} alt="not" className="w-12 h-10 mr-4" />
            <div>
              <h3 className="font-bold">Seamless Integration</h3>
              <p className="text-gray-600">
                Transform goals into competitive events directly within
                Telegram, no extra downloads needed.
              </p>
            </div>
          </li>

          <li className="flex items-start">
            <img src={s5} alt="not" className="w-12 h-10 mr-4" />
            <div>
              <h3 className="font-bold">Join and Bet</h3>
              <p className="text-gray-600">
                Seamlessly enter challenges and place wagers directly in chat.
                Experience the thrill of competition without interrupting your
                Telegram flow.
              </p>
            </div>
          </li>

          <li className="flex items-start">
            <img src={s3} alt="not" className="w-12 h-10 mr-4" />
            <div>
              <h3 className="font-bold">Amplified Engagement</h3>
              <p className="text-gray-600">
                Turn spectators into active participants. Boost community
                engagement as viewers place side bets, amplifying the stakes and
                excitement for everyone.
              </p>
            </div>
          </li>
        </ul>

        <div className={`${styles.marginX} mt-[10px] mb-[80px]`}>
          <CatButton
            text="Boost your Engagement"
            bgColor="#DEFF5A"
            iconRight={right1}
            onClick={() => console.log('Join clicked')}
            link="https://t.me/catoffgaming"
            isExternal={true}
          />
        </div>
      </div>
      <div className="w-full md:w-1/2 mb-8 md:mb-0 hidden md:block">
        <img
          src={sec3}
          alt="Telegram Bot Illustration"
          className="max-w-full h-[420px]"
        />
      </div>
    </div>
  )
}

export default Sec3_Telegram
