const styles = {
  heading:
    "md:text-[54px] text-[25px] xs:text-[28px] text-black md:leading-[76.8px] leading-[58.8px] font-clash-grotesk font-bold",

  subheading:
    "md:text-[30px] text-[20px]  md:leading-[64.8px] leading-[38.8px] w-full font-clash-grotesk font-semibold",

  paragraph:
    "font-clash-grotesk font-light text-red md:text-[16px] text-[14px] md:leading-[30.8px] leading-[26.8px]",

  flexCenter: "flex justify-center items-center",
  flexStart: "flex justify-center items-start",
  flexEnd: "flex items-end",

  paddingX: "sm:px-16 px-6",
  paddingY: "sm:py-16 py-6",
  paddingL: "sm:pl-16 pl-6",
  padding: "sm:px-16 px-6 sm:py-12 py-4",

  marginX: "sm:mx-16 mx-30",
  marginL: "sm:ml-16 ml-6",
  marginY: "sm:my-16 my-6",
};

export default styles;
