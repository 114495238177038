import React from 'react'
import Spark from '../../assets/spark.svg' 
import take2 from '../../assets/take2.svg' 
import leftWave from '../../assets/LeftWave.svg' 
import styles from '../../styles' 

function Section_1() {
  const challenges = [
    'Fitness',
    'Gaming',
    'Art',
    'Social Media',
    'Travel',
    'Sports',
    'Adventure',
    'Random',
    'Lifestyle',
    'Events',
  ]

  return (
    <div
      className={`${styles.marginX} ${styles.marginY} ${styles.paddingX} text-left flex flex-col md:flex-row items-center md:items-start justify-between md:gap-40`}
    >
      <div className="flex flex-col  md:justify-between">
        <h2 className="text-5xl font-bold font-clash-grotesk">
          Your Skills, Your Challenges, Your Winnings
        </h2>
        <p className="text-2xl text-gray-600 font-clash-grotesk mt-4">
          From fitness to cooking, turn your passions into exciting competitions
          and win big.
        </p>
        <div className="flex md:hidden justify-center w-full mt-8 w-[80%]">
          <img src={take2} alt="" className="w-9/10 h-auto" />
        </div>

        <div
          className={`grid grid-cols-2 gap-4 md:gap-8 max-w-lg mx-2 mt-6 md:mt-10`}
        >
          {challenges.map((challenge, index) => (
            <div
              key={index}
              className={`flex items-center text-lg md:text-2xl font-sf-pro text-gray-900`}
            >
              <img src={Spark} alt="Spark Icon" className="w-6 h-6 mr-2" />
              {challenge}
            </div>
          ))}

        </div>
        <img
            src={leftWave}
            alt="Left Wave"
            className="max-w-xs hidden sm:block w-[70px]"
          />
      </div>

      <div className="hidden md:flex justify-center w-full mt-8 md:mt-0 md:w-[60%] w-[80%]">
        <img src={take2} alt="" className="w-9/10 h-auto" />
      </div>
    </div>
  )
}

export default Section_1
