
import React, { useState, useRef, useEffect } from 'react'
import { useSpring, animated } from '@react-spring/web'
import plusButton from '../../assets/plus_button.svg'
import crossButton from '../../assets/cross_button.svg'
import getContentById from './expandableContent'

function ExpandableCard({ mainImage, month, title, cardId }) {
  const [isExpanded, setIsExpanded] = useState(false)
  const contentRef = useRef(null)
  const [height, setHeight] = useState(0)

  const toggleCard = () => {
    setIsExpanded(!isExpanded)
  }

  useEffect(() => {
    if (contentRef.current) {
      setHeight(contentRef.current.scrollHeight)
    }
  }, [isExpanded])

  const expandAnimation = useSpring({
    height: isExpanded ? `${height + 120}px` : '0px',
    opacity: isExpanded ? 1 : 0,
    config: { tension: 300, friction: 20 },
  })

  const currentContent = getContentById(cardId)
  const displayContent = isExpanded ? currentContent.expanded : currentContent.collapsed

  return (
    <div
      className={`relative flex flex-col md:p-5 border-2 border-black shadow-lg bg-white max-w-lg my-4 md:max-w-3xl md:my-8 mx-[20px]`}
    >
      {!isExpanded ? (
        <div className="flex flex-col md:flex-row-reverse md:justify-center p-6 md:items-center md:gap-10">
          <div className="flex flex-col items-start ">
            <div className="absolute top-4 right-4 text-[#72736E] text-md font-bold border border-black px-2 py-1 rounded-md bg-white z-10">
              Phase {month}
            </div>
            <h3 className="text-[#72736E] text-2xl font-bold mt-4 md:text-3xl">
              Month {month}
            </h3>
            <h4 className="text-[#161A0F] text-2xl font-bold mt-2 md:mt-4 md:text-4xl">
              {title}
            </h4>
          </div>
          <div className="w-full mt-4 md:mt-0 md:w-1/2">
            <img
              src={mainImage}
              alt="Adventurer"
              className="w-full h-auto object-cover"
            />
          </div>
        </div>
      ) : (
        ''
      )}

      <button
        onClick={toggleCard}
        className="absolute bottom-4 right-4 focus:outline-none"
        aria-expanded={isExpanded}
        aria-controls="expandable-content"
      >
        <img
          src={isExpanded ? crossButton : plusButton}
          alt={isExpanded ? 'Collapse' : 'Expand'}
          className="w-8 h-8"
        />
      </button>

      <animated.div
        id="expandable-content"
        style={expandAnimation}
        className="overflow-hidden flex flex-col md:flex-row items-center mt-4 px-4 md:px-6"
        ref={contentRef}
      >
        {displayContent.map((item, index) => (
          <div
            key={index}
            className="flex flex-col items-center m-4 border-2 border-gray-300 rounded-md mb-4 w-72 bg-white shadow-md"
          >
            <img
              src={item.imagePath}
              alt={item.title}
              className="w-64 h-36 object-cover mb-4"
            />
            <h5 className="text-lg font-bold text-center mb-2">{item.title}</h5>
            <p className="text-center mb-2">{item.description}</p>
            {item.reward && (
              <p className="text-[#223009] text-center font-semibold">
                {item.reward}
              </p>
            )}
          </div>
        ))}
      </animated.div>
    </div>
  )
}

export default ExpandableCard
