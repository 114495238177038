import React from 'react'
import icon from '../assets/msg.svg'
import arrow from '../assets/Arrow.svg'

const FooterButton = () => {
  return (
    <div className="relative inline-block overflow-visible group">
      <a
        href="mailto:tony@catoff.xyz?subject=Inquiry&body=Hello, I would like to know more about..."
        className="relative flex items-center p-5 bg-black border-2 border-black rounded-lg cursor-pointer transition-transform duration-300 ease-in-out transform group-hover:translate-x-[-10px] group-hover:translate-y-[-3px] active:translate-x-[-2px] active:translate-y-[-2px] active:scale-95"
        style={{
          textDecoration: 'none',
          fontFamily: 'Instrument Serif',
          fontSize: '16px',
        }}
      >
        <span className="absolute inset-0 bg-white transition-transform duration-300 ease-in-out rounded-lg group-hover:translate-y-[-5px] hover:w-full z-0"></span>

        <span
          className="relative text-black text-[28px] font-serif font-normal tracking-wider break-words text-left block mt-0 z-10 lg:text-[28px] md:text-[24px] sm:text-[20px]"
          style={{
            fontFamily: 'Instrument Serif',
            fontWeight: 'bolder',
            letterSpacing: '0.42px',
          }}
        >
          Reach out to us!
        </span>

        <div className="relative w-1/2 ml-4 z-10">
          <img
            src={icon}
            alt="Message Icon"
            className="w-full transition-transform duration-300 rounded-lg"
          />
        </div>
      </a>
      <div className="flex flex-row items-center justify-center mt-2 gap-5">
        <p
          style={{
            color: 'white',
            fontSize: '32px',
            fontFamily: "'Ananda Black Personal Use', sans-serif",
            fontWeight: '400',
            letterSpacing: '0.42px',
            wordWrap: 'break-word',
            transform: 'skew(-10deg)',
            marginTop: '12px',
            // marginBottom:"100px"
          }}
          className="text-center text-[32px] md:text-[28px] sm:text-[24px]"
        >
          Click me
        </p>

        <img src={arrow} alt="Arrow" className="mt-2" />
      </div>
    </div>
  )
}

export default FooterButton
