import React from 'react'
import {
  Hero,
  Section1,
  Section2,
  Section3,
  Footer,
  Header,
} from '../components/index'
import Marquee from '../components/Home/Marquee'
import bigSparkle from '../assets/BigSparkel.svg'
const LandingPage = () => {
  return (
    <div className="main">
      <div className="header mt-10">
        <Header />
      </div>
      <div className="hero">
        <Hero />
        <Marquee />
      </div>
      <div className="flex flex-col items-end w-full pr-[50px]">
        <p className="text-[#313328] text-2xl font-medium break-words font-[Clash Grotesk]  mt-[100px]">
          Scroll for More ↓
        </p>
        <img
          src={bigSparkle}
          alt="Big Sparkle"
          className="max-w-xs hidden sm:block  mt-[100px]"
        />
      </div>

      <div className="section_1 ">
        <div className="flex  justify-center">
          <div className="w-[90%]  opacity-5 border-2 border-black mt-20"></div>
        </div>

        <Section1 />
      </div>
      <div className="section_2 md:mt-40 mt-20">
        <div className="flex  justify-center">
          <div className="w-[90%]  opacity-5 border-2 border-black "></div>
        </div>
        <Section2 />
      </div>
      <div className="section_3  md:mt-40 mt-20">
        <div className="flex  justify-center">
          <div className="w-[90%]  opacity-5 border-2 border-black mt-20"></div>
        </div>
        <Section3 />
      </div>
      <div className="footer  md:mt-40 mt-20">
        <Footer />
      </div>
    </div>
  )
}

export default LandingPage
