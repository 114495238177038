import React from 'react'
import WinIllustration from '../../assets/take4.svg' 
import styles from '../../styles' 

function Section_3() {
  return (
    <div
    className={`flex flex-col items-center justify-between  ${styles.marginY} ${styles.marginX} md:flex-row `}
  >
      <div
        className={`flex flex-col justify-between w-full md:w-1/2 order-2 md:order-1 ${styles.paddingX} gap-150`}
      >
      <h2 className={`text-5xl font-bold font-clash-grotesk`}>
          Win and Celebrate
        </h2>
        <div className="md:hidden my-4">
          <img
            src={WinIllustration}
            alt="Winning Illustration"
            className="w-full h-auto"
          />
        </div>

        <h3 className="text-2xl text-gray-600 mb-5 font-clash-grotesk">
          Victory is even sweeter with instant payouts!
        </h3>
        <p className={`text-2xl text-gray-600 font-clash-grotesk mt-2`}>
          Challenge your friends, conquer your goals, and claim your
          well-deserved winnings. Catoff leverages external validators,
          ZKproofs, and dynamic NFT smart contracts to ensure transparent
          challenges and automated payouts. Experience the thrill of winning
          like never before!
        </p>
      </div>
      <div className="order-1 md:order-2 w-full md:w-1/2 hidden md:flex justify-center md:justify-start mb-6 md:mb-0">
        <img
          src={WinIllustration}
          style={{
             marginLeft:"150px"
          }}
          alt="Winning Illustration"
         className="w-full h-auto md:w-3/4"
        />
      </div>
    </div>
  )
}

export default Section_3
