import React, { useState, useEffect } from 'react'
import { useSwipeable } from 'react-swipeable'
import icon from '../../assets/logo.png'
import styles from '../../styles'
import { useNavigate } from 'react-router-dom'

const Sidebar = ({ isOpen, setIsOpen }) => {
  const [activeItem, setActiveItem] = useState(null)
  const navigate=useNavigate()
  const handlers = useSwipeable({
    onSwipedLeft: () => setIsOpen(false),
    onSwipedRight: () => setIsOpen(true),
  })

  const handleScrollToSection = (id) => {
    const section = document.getElementById(id)
    if (section) {
      section.style.borderRadius = '8px'
      section.style.padding = '16px'
      section.scrollIntoView({ behavior: 'smooth' })
      section.style.backgroundColor = '#00000012'
      setTimeout(() => {
        section.style.backgroundColor = ''
      }, 1000)
    }
    setActiveItem(id)
  }

  const handleScroll = () => {
    const sections = document.querySelectorAll('section')
    let index = -1
    sections.forEach((section, i) => {
      if (window.scrollY >= section.offsetTop - 10) {
        index = i
      }
    })
    setActiveItem(sections[index]?.id || null)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <div
      {...handlers}
      className={`bg-white w-[240px] h-[90vh] m-[10px] p-[10px] fixed top-6 left-0  rounded-[8px] border-r border-[#dcdcdc] overflow-y-auto scrol transition-transform duration-300 ease-in-out ${
        isOpen ? 'translate-x-0' : 'translate-x-[-100%]'
      } md:translate-x-0`}
    >
      <div className={`flex flex-col`}>
        <div className=" flex justify-between">
          <img src={icon} alt="Logo" className="max-w-[100px] h-auto"   onClick={() => navigate('/')}/>
          <button
            onClick={() => setIsOpen(false)}
            className="block md:hidden focus:outline-none"
          >
            <svg
              className="w-6 h-6 text-black"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>

        <h2 className="text-[16px] text-[#221f20] font-clash-grotesk font-medium mb-[15px] rounded-[5px]">
          Catpaper
        </h2>
      </div>
      <ul className="flex flex-col list-none p-0 m-0 text-black text-[17px] font-clash-grotesk font-medium text-right items-end">
        <li
          className={`p-[12px] pr-[15px] w-[90%] cursor-pointer text-[14px] text-[#333] rounded-[5px] mb-[5px] ${
            activeItem === 'executive-summary'
              ? 'bg-[#f1f1f1]'
              : 'bg-transparent hover:bg-[#f1f1f1]'
          } transition-colors duration-300`}
          onClick={() => handleScrollToSection('executive-summary')}
        >
          Executive Summary
        </li>
        <li
          className={`p-[12px] pr-[15px] w-[90%] cursor-pointer text-[14px] text-[#333] rounded-[5px] mb-[5px] ${
            activeItem === 'introduction'
              ? 'bg-[#f1f1f1]'
              : 'bg-transparent hover:bg-[#f1f1f1]'
          } transition-colors duration-300`}
          onClick={() => handleScrollToSection('introduction')}
        >
          Introduction
        </li>
        <li
          className={`p-[12px] pr-[15px] w-[90%] cursor-pointer text-[14px] text-[#333] rounded-[5px] mb-[5px] ${
            activeItem === 'market-opportunity'
              ? 'bg-[#f1f1f1]'
              : 'bg-transparent hover:bg-[#f1f1f1]'
          } transition-colors duration-300`}
          onClick={() => handleScrollToSection('market-opportunity')}
        >
          Market Opportunity
        </li>
        <li
          className={`p-[12px] pr-[15px] w-[90%] cursor-pointer text-[14px] text-[#333] rounded-[5px] mb-[5px] ${
            activeItem === 'product-overview'
              ? 'bg-[#f1f1f1]'
              : 'bg-transparent hover:bg-[#f1f1f1]'
          } transition-colors duration-300`}
          onClick={() => handleScrollToSection('product-overview')}
        >
          Product Overview
        </li>
        <li
          className={`p-[12px] pr-[15px] w-[90%] cursor-pointer text-[14px] text-[#333] rounded-[5px] mb-[5px] ${
            activeItem === 'technology-stack'
              ? 'bg-[#f1f1f1]'
              : 'bg-transparent hover:bg-[#f1f1f1]'
          } transition-colors duration-300`}
          onClick={() => handleScrollToSection('technology-stack')}
        >
          Technology Stack
        </li>
        <li
          className={`p-[12px] pr-[15px] w-[90%] cursor-pointer text-[14px] text-[#333] rounded-[5px] mb-[5px] ${
            activeItem === 'user-experience'
              ? 'bg-[#f1f1f1]'
              : 'bg-transparent hover:bg-[#f1f1f1]'
          } transition-colors duration-300`}
          onClick={() => handleScrollToSection('user-experience')}
        >
          User Experience
        </li>
        <li
          className={`p-[12px] pr-[15px] w-[90%] cursor-pointer text-[14px] text-[#333] rounded-[5px] mb-[5px] ${
            activeItem === 'tokenomics'
              ? 'bg-[#f1f1f1]'
              : 'bg-transparent hover:bg-[#f1f1f1]'
          } transition-colors duration-300`}
          onClick={() => handleScrollToSection('tokenomics')}
        >
          Tokenomics
        </li>
        <li
          className={`p-[12px] pr-[15px] w-[90%] cursor-pointer text-[14px] text-[#333] rounded-[5px] mb-[5px] ${
            activeItem === 'business-model'
              ? 'bg-[#f1f1f1]'
              : 'bg-transparent hover:bg-[#f1f1f1]'
          } transition-colors duration-300`}
          onClick={() => handleScrollToSection('business-model')}
        >
          Business Model
        </li>
        <li
          className={`p-[12px] pr-[15px] w-[90%] cursor-pointer text-[14px] text-[#333] rounded-[5px] mb-[5px] ${
            activeItem === 'roadmap'
              ? 'bg-[#f1f1f1]'
              : 'bg-transparent hover:bg-[#f1f1f1]'
          } transition-colors duration-300`}
          onClick={() => handleScrollToSection('roadmap')}
        >
          Roadmap
        </li>
        <li
          className={`p-[12px] pr-[15px] w-[90%] cursor-pointer text-[14px] text-[#333] rounded-[5px] mb-[5px] ${
            activeItem === 'community-and-support'
              ? 'bg-[#f1f1f1]'
              : 'bg-transparent hover:bg-[#f1f1f1]'
          } transition-colors duration-300`}
          onClick={() => handleScrollToSection('community-and-support')}
        >
          Community and Support
        </li>
        <li
          className={`p-[12px] pr-[15px] w-[90%] cursor-pointer text-[14px] text-[#333] rounded-[5px] mb-[5px] ${
            activeItem === 'legal-and-compliance'
              ? 'bg-[#f1f1f1]'
              : 'bg-transparent hover:bg-[#f1f1f1]'
          } transition-colors duration-300`}
          onClick={() => handleScrollToSection('legal-and-compliance')}
        >
          Legal
        </li>
        <li
          className={`p-[12px] pr-[15px] w-[90%] cursor-pointer text-[14px] text-[#333] rounded-[5px] mb-[5px] ${
            activeItem === 'conclusions'
              ? 'bg-[#f1f1f1]'
              : 'bg-transparent hover:bg-[#f1f1f1]'
          } transition-colors duration-300`}
          onClick={() => handleScrollToSection('conclusions')}
        >
          Conclusion
        </li>
        <li
          className={`p-[12px] pr-[15px] w-[90%] cursor-pointer text-[14px] text-[#333] rounded-[5px] mb-[5px] ${
            activeItem === 'appendix'
              ? 'bg-[#f1f1f1]'
              : 'bg-transparent hover:bg-[#f1f1f1]'
          } transition-colors duration-300`}
          onClick={() => handleScrollToSection('appendix')}
        >
          Appendix
        </li>
      </ul>
    </div>
  )
}

export default Sidebar
