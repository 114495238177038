import React from 'react'
import { FaTelegramPlane } from 'react-icons/fa'
// import { FaCheckCircle } from 'react-icons/fa'
import tele1 from '../../assets/tele1.png'
import s1 from '../../assets/Telegram/s1.png'
import s2 from '../../assets/Telegram/s2.png'
import s3 from '../../assets/Telegram/s3.png'
import styles from '../../styles'
import CatButton from '../Home/CatButton'
import right1 from '../../assets/right.png'
const Sec2_Telegram = () => {
  return (
    <div className="flex flex-col md:flex-row justify-center align-center p-5 md:p-10 ">
      {/* Left Section: Image */}
      <div className="w-full md:w-1/2 mb-8 md:mb-0 hidden md:block">
        <img
          src={tele1}
          alt="Telegram Bot Illustration"
          className="max-w-full h-[420px]"
        />
      </div>

      {/* Right Section: Content */}
      <div className="flex flex-col justify-center align-center w-full md:w-[40%] md:pl-10">
        {/* Heading */}
        <h2
          className={` font-600 flex items-center mb-4  text-[32px] xs:text-[28px] text-black  font-clash-grotesk font-semibold`}
        >
          <FaTelegramPlane className="mr-2 text-3xl" /> Catoff Telegram Bot
        </h2>
        <p
          className={`text-gray-600 mb-6  font-clash-grotesk text-[24px] font-200 `}
        >
          Engage in simple, and seamless steps
        </p>

        {/* Features */}
        <ul className="space-y-4">
          <li className="flex items-start">
            <img src={s1} alt="not" className="w-12 h-10 mr-4" />
            <div>
              <h3 className="font-bold">Effortless Challenge Creation</h3>
              <p className="text-gray-600">
                Create challenges in 6 steps with intelligent descriptions and
                custom NFTs.
              </p>
            </div>
          </li>

          <li className="flex items-start">
            <img src={s2} alt="not" className="w-12 h-10 mr-4" />
            <div>
              <h3 className="font-bold">Wager-Based Competitions</h3>
              <p className="text-gray-600">
                Turn community goals into exciting, customizable contests.
              </p>
            </div>
          </li>

          <li className="flex items-start">
            <img src={s3} alt="not" className="w-12 h-10 mr-4" />
            <div>
              <h3 className="font-bold">Boost Engagement</h3>
              <p className="text-gray-600">
                Elevate participation with challenges tailored to your
                community.
              </p>
            </div>
          </li>
        </ul>

        <div className={`${styles.marginX} mt-[10px] mb-[80px]`}>
          <CatButton
            text="Create your Challenge"
            bgColor="#DEFF5A"
            iconRight={right1}
            onClick={() => console.log('Join clicked')}
            link=" https://t.me/CatoffTestingBot"
            isExternal={true}
          />
        </div>
      </div>
    </div>
  )
}

export default Sec2_Telegram
