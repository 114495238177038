import React from 'react'

const Marquee = () => {
  return (
    <div className="custom-button-wrapper hover-pause inline-block relative overflow-visible w-full bg-white">
      <div className="whitespace-nowrap overflow-hidden border-t-2 border-b-2 border-dashed border-black py-2 shadow-cut">
        <div className="inline-block animate-marquee text-black font-clash-grotesk font-medium uppercase text-[24px]">
          <span className="px-4">READY TO CHALLENGE?</span>
          <span className="mx-2 text-[24px]">✦</span>
          <span className="px-4">Win Instant Payouts</span>
          <span className="mx-2 text-[24px]">✦</span>
          <span className="px-4">Place Your Wagers</span>
          <span className="mx-2 text-[24px]">✦</span>
          <span className="px-4">PLACE SIDE BETS</span>
          <span className="mx-2 text-[24px]">✦</span>
          <span className="px-4">CHALLENGE YOUR FRIENDS</span>
          <span className="mx-2 text-[24px]">✦</span>
        </div>
        <div className="inline-block animate-marquee text-black font-clash-grotesk font-medium uppercase text-[24px]">
          <span className="px-4">READY TO CHALLENGE?</span>
          <span className="mx-2 text-[24px]">✦</span>
          <span className="px-4">Win Instant Payouts</span>
          <span className="mx-2 text-[24px]">✦</span>
          <span className="px-4">Place Your Wagers</span>
          <span className="mx-2 text-[24px]">✦</span>
          <span className="px-4">PLACE SIDE BETS</span>
          <span className="mx-2 text-[24px]">✦</span>
          <span className="px-4">CHALLENGE YOUR FRIENDS</span>
          <span className="mx-2 text-[24px]">✦</span>
        </div>
      </div>
    </div>
  )
}

export default Marquee
