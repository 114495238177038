import React from 'react'
import goal from '../../assets/graphic.svg'
import firstmove from '../../assets/firstmove.svg'
import secondmove from '../../assets/secondmove.svg'
import thirdmove from '../../assets/thirdmove.svg'
import forthmove from '../../assets/forthmove.svg'
import fifthmove from '../../assets/fifthmove.svg'
import sixthmove from '../../assets/sisxthmove.svg'
import msgbub from '../../assets/msgbub.svg'
import ExpandableCard from './ExpandableCard'
import month1 from '../../assets/month1.svg'
import month2 from '../../assets/month2.svg'
import month3 from '../../assets/month3.png'
import month4 from '../../assets/month4.svg'
import month5 from '../../assets/month5.svg'
import month6 from '../../assets/month6.svg'
import month7 from '../../assets/month7.svg'
function Section_2_Air() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img src={goal} alt="" width={250} />
      <div  style={{
        display: 'flex',
        flexDirection: 'row',
        // justifyContent: 'center',
        alignItems: 'center',
      }}>

      <img src={firstmove} alt="" width={100} />
      <img src={msgbub} alt="" width={100} />
      </div>

      <ExpandableCard
        mainImage={month1}
        month="1"
        title="The Adventurer’s Welcome"
        cardId={1}
      />
      <img src={secondmove} alt="" width={100} />
      <ExpandableCard mainImage={month2} month="2" title="Early Engagement" cardId={2} />
      <img src={thirdmove} alt="" width={15} />
      <ExpandableCard mainImage={month3} month="3" title="Community Builder" cardId={3} />
      <img src={forthmove} alt="" width={20} />
      <ExpandableCard mainImage={month4} month="4" title="Increased Activity" cardId={4} />
      <img src={fifthmove} alt="" width={200} />
      <ExpandableCard mainImage={month5} month="5" title="Power User" cardId={5} />
      <img src={sixthmove} alt="" width={200} />
      <ExpandableCard
        mainImage={month6}
        month="6"
        title="Leadership & Legacy"
        cardId={6}
      />
      <img src={firstmove} alt="" width={100} />
      <ExpandableCard mainImage={month7} month="7" title="The Conqueror"  cardId={7}/>
      <div className="h-52"></div>
    </div>
  )
}

export default Section_2_Air
