import React from 'react'
import styles from '../../styles'
import coin from '../../assets/coin.svg'
import token from '../../assets/map.svg'

function Section_1_Air() {
  return (
    <>
      <div
        className={`${styles.flexCenter} ${styles.paddingY} flex-col md:flex-row text-center md:text-left`}
      >
        <div className="flex flex-row items-center  justify-center md:justify-start">
          <h1 className={`${styles.heading} text-black text-3xl md:text-4xl`}>
            Token
          </h1>
          <img src={coin} alt="Token" className=" md:w-24" />
        </div>
        <div className="flex flex-row items-center  justify-center md:justify-start  md:mt-0">
          <h2 className={`${styles.heading} text-black text-2xl md:text-3xl whitespace-nowrap`}>
            Airdrop Quest Roadmap
          </h2>
          <img src={token} alt="Token and Roadmap" className="ml-4 w-8 md:w-full" />
        </div>
      </div>

      <div
        className="text-center"
        style={{
          position: 'relative',
        }}
      >
        <img src="/roadway.png" alt="Roadway" className="my-3 mx-auto w-full md:w-auto" />
      </div>
    </>
  )
}

export default Section_1_Air
