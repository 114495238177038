import './App.css'
import AirDrop from './pages/AirDrop'
import Blinks from './pages/Blinks'
import CatPaper from './pages/CatPaper'

import Home from "./pages/Home"

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Telegram from './pages/Telegram'
import BanditNetwork from './pages/BanditNetwork'

function App() {
  return <Router>
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/catpaper" element={<CatPaper/>} />
      <Route path="/airdrop" element={<AirDrop/>} />
      <Route path="/blinks" element={<Blinks/>} />
      <Route path="/telegram" element={<Telegram/>} />
      <Route path="/quest" element={<BanditNetwork/>} />
    </Routes>
  
</Router>
}

export default App
