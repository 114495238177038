import React, { useState } from 'react'
import { Content, Footer, Header, Section2, Sidebar } from '../components'
import Section_1_Air from '../components/Airdrop/Section_1_Air'
import Section_2_Air from '../components/Airdrop/Section_2_Air'

function AirDrop() {
 
  return (
    <div className="main">
      <div className="header mt-10">
        <Header />
      </div>
      <div className='main-page-container'>

      <Section_1_Air />
      </div>
      <div className='main-page-container'>

      <Section_2_Air/>
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  )
}

export default AirDrop
