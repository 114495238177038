import React, { useState } from 'react'
import { Content, Footer, Header, Sidebar } from '../components'
import Sec1_Blinks from '../components/Blinks/Sec1_Blinks'
import Sec2_Blinks from '../components/Blinks/Sec2_Blinks'

function Blinks() {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-shrink-0 mt-10">
        <Header />
      </div>

      <div>
        <Sec1_Blinks />
      </div>
      <div>
        <Sec2_Blinks />
      </div>

      <div className="flex-shrink-0">
        <Footer />
      </div>
    </div>
  )
}

export default Blinks
