import React from 'react'
import foot from '../assets/foot.svg'
import billa from '../assets/billa.svg'
import logo from '../assets/logo.png'
import x from '../assets/x.svg'
import instagram from '../assets/instagram.svg'
import tele from '../assets/tele.png'
import styles from '../styles'
import wave from '../assets/wave.svg'
import star from '../assets/star.svg'
import airdrop from '../assets/airdrop.svg'

import FooterButton from './FooterButton'
import { useNavigate } from 'react-router-dom'

const Footer = () => {
  const nav = useNavigate()
  return (
    <div className="text-center text-black">
      <div className="bg-[#ccff66] relative">
        <img
          src={foot}
          alt="Footer Wave"
          className="md:w-full md:h-auto  h-[280px] object-cover"
        />
        <div className="absolute top-0 sm:top-5 w-full">
          <div
            className={`flex flex-col sm:flex-row justify-between ${styles.marginX} ${styles.paddingX}`}
          >
            <div className="mb-4 hidden md:block">
              <img src={star} alt="Star" />
            </div>

            <div className="mt-4 sm:mt-10 mb-4 sm:mb-0">
              {/* <img src={contactUS} alt="Contact Us" /> */}
              <FooterButton />
            </div>
            <div className="mt-4 sm:mt-6 hidden md:block">
              <img src={airdrop} alt="Airdrop" />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#C8F72D] relative">
        <img
          src={wave}
          alt="Wave"
          className="w-full relative -top-12 -z-6 hidden md:block"
        />
        <img
          src={wave}
          alt="Wave"
          className="w-full relative -top-9 -z-6 md:hidden block md:h-auto h-[40px] object-cover"
        />
        <div
          className={`flex flex-col justify-between ${styles.paddingY} ${styles.marginX} z-10`}
        >
          <div
            className={`flex flex-col sm:flex-row justify-between items-start flex-wrap ${styles.paddingX} md:px-auto px-10`}
          >
            <div className="flex flex-col sm:flex-row justify-around items-center relative sm:top-12 gap-6 sm:gap-12">
              <img src={logo} alt="Logo" className="object-cover" />
              <img src={billa} alt="Billa" className="object-cover" />
            </div>
            <div className="flex flex-row mt-10 md:mt-auto md:w-auto w-full justify-between">
              <div className="flex flex-col text-left mb-4 sm:mb-0 sm:mr-12">
                <h3 className="text-xl sm:text-2xl mb-2 font-clash-grotesk">
                  Resources
                </h3>
                <ul className="list-none text-[#506312] text-base sm:text-lg font-clash-grotesk font-medium p-0 m-0">
                  {/* <li className="mb-2">
                    <a href="#" className="text-black">
                      Documentation
                    </a>
                  </li> */}
                  <li className="mb-2">
                    <a
                      href=""
                      className="text-black"
                      onClick={() => nav('/catpaper')}
                    >
                      Catspaper
                    </a>
                  </li>
                  <li className="mb-2">
                    <a
                      href=""
                      className="text-black"
                      onClick={() => nav('/airdrop')}
                    >
                      Airdrop Roadmap
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex flex-col text-left">
                <h3 className="text-xl sm:text-2xl mb-2 font-clash-grotesk">
                  Legal
                </h3>
                <ul className="list-none text-[#506312] text-base sm:text-lg font-clash-grotesk font-medium p-0 m-0">
                  <li className="mb-2">
                    <a href="/PrivacyPolicy.html" className="text-black">
                      Privacy
                    </a>
                  </li>
                  <li className="mb-2">
                    <a href="/TermsAndServices.html" className="text-black">
                      Terms
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex justify-center gap-5 relative md:top-12 md:mx-0 mx-auto md:my-0 my-6">
              <a
                href="https://x.com/CatoffGaming"
                className="w-8 sm:w-10 h-8 sm:h-10 inline-block bg-cover"
              >
                <img src={x} alt="Social X" className="w-full h-full" />
              </a>
              <a
                href="https://www.instagram.com/catoffgaming"
                className="w-8 sm:w-10 h-8 sm:h-10 inline-block bg-cover"
              >
                <img
                  src={instagram}
                  alt="Instagram"
                  className="w-full h-full"
                />
              </a>
              <a
                href="https://t.me/catoffgaming"
                className="w-8 sm:w-10 h-8 sm:h-10 inline-block bg-cover"
              >
                <img src={tele} alt="telegram" className="w-full h-full" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
