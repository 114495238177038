// BanditPage.js
import React from 'react';
import { BanditContextProvider, Campaign } from '@bandit-network/react';
import { Footer, Header } from '../components';
import Sec1_Bandit from '../components/Bandit/Sec1_Bandit';

const BanditNetwork = React.memo(() => {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-shrink-0 mt-10">
        <Header />
      </div>

      <div>
        <Sec1_Bandit/>
      </div>

      <div className="flex-shrink-0">
        <Footer />
      </div>
    </div>
  );
});

export default BanditNetwork;
