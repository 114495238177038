import React from 'react';
import { Link } from 'react-router-dom'; 

const ClickableButton = ({
  text,
  onClick,
  iconLeft,
  iconRight,
  bgColor = '#f8f548',
  fontSize = 'text-[18px]',
  link, 
  isExternal = false, 
  disabled = false,
}) => {
  const ButtonContent = () => (
    <>
      {iconLeft && <img src={iconLeft} alt="Left Icon" className="mr-2 w-6" />}
      <span className={`flex-grow ${fontSize}`} style={{ color: '#000000' }}>{text}</span>
      {iconRight && <img src={iconRight} alt="Right Icon" className="ml-2 w-6" />}
    </>
  );

  const buttonStyles = {
    backgroundColor: disabled ? '#B0B0B0' : bgColor,
    cursor: disabled ? 'not-allowed' : 'pointer',
    opacity: disabled ? 0.6 : 1,
  };

  const commonClasses = `custom-button flex items-center p-15 border-1 rounded border-black transition-transform ${disabled ? 'pointer-events-none' : 'hover:-translate-x-1 hover:-translate-y-1'}`;

  return (
    <div className="custom-button-wrapper inline-block relative overflow-visible">
      {link ? (
        isExternal ? (
          <a
            href={link}
            className={commonClasses}
            style={buttonStyles}
            target="_blank"
            rel="noopener noreferrer"
            onClick={disabled ? (e) => e.preventDefault() : undefined}
          >
            <ButtonContent />
          </a>
        ) : (
          <Link
            to={link}
            className={commonClasses}
            style={buttonStyles}
            onClick={disabled ? (e) => e.preventDefault() : undefined}
          >
            <ButtonContent />
          </Link>
        )
      ) : (
        <button
          className={commonClasses}
          onClick={disabled ? undefined : onClick}
          style={buttonStyles}
          disabled={disabled}
        >
          <ButtonContent />
        </button>
      )}
    </div>
  );
};

export default ClickableButton;
