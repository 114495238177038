import React from 'react'
import { Footer, Header } from '../components'
import Sec1_Telegram from '../components/Telegram/Sec1_Telegram'
import Sec2_Telegram from '../components/Telegram/Sec2_Telegram'
import Sec3_Telegram from '../components/Telegram/Sec3_Telegram'
import Sec4_Telegram from '../components/Telegram/Sec4_Telegram'

function Telegram() {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-shrink-0 mt-10">
        <Header />
      </div>
      <div>
        <Sec1_Telegram />
      </div>
      <div>
        <Sec2_Telegram />
      </div>
      <div>
        <Sec3_Telegram />
      </div>
      <div>
        <Sec4_Telegram />
      </div>
      {/* <div>
        <Sec1_Blinks />
      </div>
      <div>
        <Sec2_Blinks />
      </div> */}

      <div className="flex-shrink-0">
        <Footer />
      </div>
    </div>
  )
}

export default Telegram
