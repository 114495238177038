import React from 'react'
import styles from '../../styles'
import car from '../../assets/car.svg'

function Sec1_Blinks() {
  return (
    <div
      className={`${styles.flexCenter} ${styles.paddingY} flex-col text-center md:text-left md:mb-[0px] mb-[100px]`}
    >
      <div className="flex items-center justify-center mb-2">
        <h1 className="text-black font-bold text-4xl md:text-5xl font-[Clash Grotesk] leading-tight break-words">
          Catoff on Blinks
        </h1>
        <img src={car} alt="Logo" className="ml-2 h-20 w-20 md:h-30 md:w-30" />
      </div>

      <p className="text-gray-600 font-medium text-lg md:text-[28px] w-[90%] md:w-[80%] font-[Clash Grotesk] leading-snug break-words text-center">
        Embrace the thrill of real-time interaction with Blinks and watch your
        social experience transform into a gamified adventure.
      </p>

      <div className="flex flex-col items-center group mt-4">
        <p className="text-black text-center font-medium text-sm md:text-lg font-[SF Pro] leading-9 break-words cursor-pointer border-b border-dashed border-black pb-1">
          What are Blinks?
        </p>

        <div
          className="absolute hidden w-[80%] md:w-[50%] p-4 mt-10 text-sm text-black bg-white border rounded-lg shadow-lg group-hover:flex flex-col"
          style={{
            animation: 'grow 0.3s ease-out forwards',
            transform: 'translate(-50%, 0)',
          }}
        >
          Blinks are your gateway to instant Catoff challenges on X (formerly
          Twitter). With a single click, you can create, join, or side bet on
          Catoff challenges without leaving your X feed.
          <button className="absolute top-1 right-1 text-gray-500 hover:text-gray-700">
            &times;
          </button>
        </div>
      </div>
      <style>
        {`
          @keyframes grow {
            0% {
              transform: scale(0.5);
              opacity: 0;
            }
            100% {
              transform: scale(1);
              opacity: 1;
            }
          }
        `}
      </style>
    </div>
  )
}

export default Sec1_Blinks
