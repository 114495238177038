import React from 'react'
import ChallengeIllustration from '../../assets/take3.svg'
import styles from '../../styles'

function WagerAgainstChallenge() {
  return (
    <div
      className={`flex flex-col items-center justify-between  ${styles.marginY} ${styles.marginX} md:flex-row `}
    >
      <div
        className={`flex flex-col justify-center w-full md:w-1/2 order-1 md:order-2 ${styles.paddingL}`}
      >
        <h2 className={`text-5xl font-bold font-clash-grotesk`}>
          Wager on Any Challenge
        </h2>

        <div className="md:hidden my-4">
          <img
            src={ChallengeIllustration}
            alt="Challenge Illustration"
            className="w-full h-auto"
          />
        </div>

        <h3 className="text-2xl text-gray-600 mb-5 font-clash-grotesk">
          Pick your challenge, place your wager, compete and win.
        </h3>
        <p className={`text-2xl text-gray-600 font-clash-grotesk mt-2`}>
          Find a challenge that excites you, place your wager, or back a friend
          with Side Bets. Whether you're stepping up to the challenge yourself
          or betting on someone else's victory, your stake puts you in line for
          a share of the winnings. Get in the game and make every challenge
          count!
        </p>
      </div>
      <div className="order-2 md:order-1 w-full md:w-1/2 hidden md:flex justify-center md:justify-start mb-6 md:mb-0">
        <img
          src={ChallengeIllustration}
          alt="Challenge Illustration"
          className="w-full h-auto md:w-3/4"
        />
      </div>
    </div>
  )
}

export default WagerAgainstChallenge
