import React from 'react';
import { Link } from 'react-router-dom'; 
import CatEye from './CatEye';

const CatButton = ({
  text,
  onClick,
  iconLeft,
  iconRight,
  bgColor = '#f8f548',
  textColor = '#0f0b0f',
  fontSize = '18px',
  link, 
  isExternal = false, 
}) => {

  const ButtonContent = () => (
    <>
      {iconLeft && <img src={iconLeft} alt="Left Icon" className="mr-2 w-6" />}
      <span className="flex-grow">{text}</span>
      {iconRight && <img src={iconRight} alt="Right Icon" className="ml-2 w-6" />}
    </>
  );

  const buttonStyles = {
    backgroundColor: bgColor,
    fontSize: fontSize,
    color: textColor,
  };

  return (
    <>
      <div
        style={{
          position: 'relative',
          top: '22px',
          left: '110px',
          zIndex: 100,
          width: '60px',
        }}
      >
        <CatEye />
      </div>

      <div className="custom-button-wrapper inline-block relative overflow-visible">
        {link ? (
          isExternal ? (
            <a
              href={link}
              className="custom-button flex items-center px-8 py-4 border-2 rounded bg-yellow-400 border-black transition-transform hover:-translate-x-1 hover:-translate-y-1"
              style={buttonStyles}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ButtonContent />
            </a>
          ) : (
            <Link
              to={link}
              className="custom-button flex items-center px-8 py-4 border-2 rounded bg-yellow-400 border-black transition-transform hover:-translate-x-1 hover:-translate-y-1"
              style={buttonStyles}
            >
              <ButtonContent />
            </Link>
          )
        ) : (
          <button
            className="custom-button flex items-center px-8 py-4 border-2 rounded bg-yellow-400 border-black transition-transform hover:-translate-x-1 hover:-translate-y-1"
            onClick={onClick}
            style={buttonStyles}
          >
            <ButtonContent />
          </button>
        )}
      </div>
    </>
  );
};

export default CatButton;
